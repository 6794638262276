<template>
	<div v-loading="loading" class="mb-3">
		<div class="card no-box-shadow">
			<div class="card-body p-1">
				<div class="flex  card-recommend-product position-relative">
					<div class="w30 bd-color">
						<img :src="item.images[0].image" alt="" v-if="item.images.length > 0" class="img-dim"
							 data-zoomable/>

					</div>
					<div class="w70 flex">
						<div class="grid grid-cols-1  product-info"
							 :class="{'w100': hasDiscount() === false,'w70':hasDiscount() === true}">
							<div class="col-span-1 font-semibold cursor-pointer" v-on:click="onOpenUrl">{{ item.description }}</div>
							<div class="col-span-1 price-color font-semibold" v-if="hasDiscount() === false">
								{{ item.sales_price }}:-
							</div>
							<div class="col-span-1 price-color font-semibold" v-else>{{
									product.discounted_price
								}}:-
							</div>
						</div>
						<div class="grid grid-cols-1 w30 text-center" v-if="hasDiscount() === true">
							<svg width="25" height="25" viewBox="0 0 25 25" fill="none" class="discount-icon"
								 xmlns="http://www.w3.org/2000/svg">
								<path
									d="M9.83171 1.10672C11.2947 -0.367027 13.6831 -0.367027 15.1586 1.09547L16.0715 2.00672C16.4216 2.35672 16.8968 2.55797 17.3969 2.55797H18.6849C20.7607 2.55797 22.4488 4.24422 22.4488 6.31922V7.60797C22.4488 8.10672 22.6489 8.58172 22.999 8.93172L23.8993 9.83172C24.6121 10.5317 24.9997 11.4817 24.9997 12.4817C25.0122 13.4817 24.6246 14.433 23.9243 15.1442L22.999 16.0692C22.6489 16.4192 22.4488 16.8942 22.4488 17.3955V18.6817C22.4488 20.7567 20.7607 22.4455 18.6849 22.4455H17.3969C16.8968 22.4455 16.4216 22.6442 16.0715 22.9942L15.1711 23.8942C14.4334 24.633 13.4705 24.9942 12.5077 24.9942C11.5448 24.9942 10.582 24.633 9.84421 23.908L8.93138 22.9942C8.58125 22.6442 8.10608 22.4455 7.6059 22.4455H6.31793C4.24218 22.4455 2.55407 20.7567 2.55407 18.6817V17.3955C2.55407 16.8942 2.354 16.4192 2.00387 16.0567L1.10354 15.1692C-0.359485 13.708 -0.37199 11.3192 1.09104 9.84547L2.00387 8.93172C2.354 8.58172 2.55407 8.10672 2.55407 7.59422V6.31922C2.55407 4.24422 4.24218 2.55797 6.31793 2.55797H7.6059C8.10608 2.55797 8.58125 2.35672 8.93138 2.00672L9.83171 1.10672ZM15.7213 14.6192C15.1086 14.6192 14.6209 15.108 14.6209 15.7067C14.6209 16.3192 15.1086 16.8067 15.7213 16.8067C16.3216 16.8067 16.8092 16.3192 16.8092 15.7067C16.8092 15.108 16.3216 14.6192 15.7213 14.6192ZM16.4841 8.50672C16.059 8.08297 15.3712 8.08297 14.9461 8.50672L8.51873 14.9317C8.09358 15.3567 8.09358 16.0567 8.51873 16.4817C8.71881 16.6942 8.99391 16.8067 9.28151 16.8067C9.58162 16.8067 9.85672 16.6942 10.0568 16.4817L16.4841 10.058C16.9093 9.63297 16.9093 8.93172 16.4841 8.50672ZM9.29401 8.19422C8.6938 8.19422 8.19361 8.68172 8.19361 9.28172C8.19361 9.89547 8.6938 10.3817 9.29401 10.3817C9.89423 10.3817 10.3819 9.89547 10.3819 9.28172C10.3819 8.68172 9.89423 8.19422 9.29401 8.19422Z"
									fill="#C61515"/>
							</svg>
							<span class="percentage-off">{{ getPercentage() }}% {{ $t('discount') }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";

export default {
	name: "blogPreviewRecommendProduct",
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	data: function () {
		return {
			product: {},
			loading: false
		}
	},
	methods: {
		hasDiscount() {
			let currentDate = moment().format('YYYY-MM-DD')
			if (this.product.scheduled_campaign_start_date && this.product.scheduled_campaign_end_date) {
				let startDate = moment(this.product.scheduled_campaign_start_date).format('YYYY-MM-DD')
				let endDate = moment(this.product.scheduled_campaign_end_date).format('YYYY-MM-DD')
				if (currentDate >= startDate && currentDate <= endDate) {
					return true
				} else {
					return false
				}
			} else {
				return false
			}
		},
		getPercentage() {
			let discountedPrice = parseFloat(this.product.sales_price) - parseFloat(this.product.discounted_price)
			let total = (discountedPrice / parseFloat(this.product.sales_price)) * 100
			let percentile = Math.ceil(total)
			if (percentile) {
				return percentile
			} else {
				return ''
			}
		},
		onOpenUrl() {
			let routeData = this.$router.resolve({
				name: 'article-detail', params: {
					article_id: this.item.id
				}
			});
			window.open(routeData.href, '_blank');
		}
	},
	mounted() {
		// console.log(this.item)
	}
}
</script>

<style scoped>
.price-color {
	color: #0501A1;

}

.card-recommend-product {
	height: 110px;
}

.w100 {
	width: 100% !important;
}

.w30 {
	width: 30% !important;
}

.w70 {
	width: 70% !important;
}

.img-dim {
	height: 100% !important;
	width: 100% !important;
}

.bd-color {
	border: 1px solid #f1f4f6;
}

.product-info {
	padding-left: 13px;
	padding-right: 13px;
}

.mx-auto {
	margin: auto;
}

.discount-icon {
	position: relative;
	top: 21px;
	left: 9px;
}

.percentage-off {
	color: #EC0E0E;
	padding-right: 20px;
	padding-top: 8px;
}

.no-box-shadow {
	box-shadow: none !important;
}
</style>
